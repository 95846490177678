import React from 'react'
import { HashRouter, Route, BrowserRouter } from 'react-router-dom';
import Home from './pages/Home'
import Lorenz from './pages/Lorenz'
import Footer from './components/Footer'
import './App.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <HashRouter>
          <Route exact path='/' component={Home} key={Math.random(1,9999)}/>
          <Route exact path='/lorenz/' component={Lorenz} key={Math.random(1,9999)}/>
          <Route exact path='/lorenz/:section' component={Lorenz} key={Math.random(1,9999)}/>
        </HashRouter>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
