import React from 'react'
import { Link } from "react-router-dom"
import { scroller } from 'react-scroll'
import logo from "../assets/logo.png"
import "../css/nav.css"

function Navigation(props) {

    const page = props.page;

    const scrollToCollection = () => {
        scroller.scrollTo("contentMM", {
            duration: 1500,
            delay: 0,
            smooth: "easeInOutQuart",
          });
    }

    return (
        <div className="nav">
            <div className="left">
                <Link to={'/'}><img src={logo} className="logo" alt="Logo"/></Link>
            </div>
            <div className="right">
                {page === 'Lorenz' ? <span className="mint" onClick={() => scrollToCollection()}>Mint</span> : <Link className="mint" to={'./lorenz/mint'}>Mint</Link>}
            </div>
        </div>
    )
}

export default Navigation
