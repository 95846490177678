import React from 'react'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'
import Navigation from '../components/Navigation'

import collage from '../assets/animation1.gif'
import "../css/Home.css"
import "../css/roadmap.css"


import image1 from '../assets/bank/1redo.png'
import image2 from '../assets/bank/2.png'
import image3 from '../assets/bank/3.png'
import image4 from '../assets/bank/4.png'
import image5 from '../assets/bank/5.png'

function Home() {

    const bank = [image1, image2, image3, image4, image5]

    const id = getRandomInt(0, 4);
    const image = bank[id];

    const scrollToCollection = () => {
        scroller.scrollTo("collection-container", {
            duration: 1500,
            delay: 0,
            smooth: "easeInOutQuart",
          });
    }

    const scrollToDesc = () => {
        scroller.scrollTo("Home", {
            duration: 1500,
            delay: 0,
            smooth: "easeInOutQuart",
          });
    }

    return (
        <div>
            <div className="ex-intro">
                <div className="intro">
                    <Navigation page={'Home'}/>
                    <div className="intro-container">
                        <div className="introText">
                            <h1>Chaos Theory</h1>
                            <p className="introPara">Generative art inspired by the unpredictability of nature, living on the Solana Blockchain.</p>
                            <span className="button" onClick={() => scrollToCollection()}>View Collections</span>
                            {/* spacer */}
                            <div style={{height: '50px'}} />
                        </div>
                        <div>
                            <img src={image} alt="introImg" className="introImg" />
                        </div>
                    </div>
                    {/* spacer */}
                    <div style={{width: '1px', height: '5vh'}} />
                    <div className="center">
                        <span onClick={() => scrollToDesc()}><img src={image1} alt="scroll" className="introScroll"/></span>
                    </div>
                </div>
            </div>
            <div className="Home">
                {/* spacer */}
                <div style={{height: '75px'}} />
                <div className="fourth">
                    <div className="fourthText">
                        <h1>Chaos Theory</h1>
                        <p className="fourthPara">Chaos Theory is a branch of mathematics that seeks to explain the butterfly effect—where a small change in the initial conditions result in significant and unpredictable consequences. Chaos exists in many natural systems, including fluid flow, motion of planets, weather and climate.</p>
                    </div>
                </div>
                <div className="fourth">
                    <div className="fourthText">
                        <h1>Our Collections</h1>
                        <p className="fourthPara">Our NFTs are sets of initial conditions to different examples of Chaos Theory. Each NFT in a collection is demonstrably and unpredictably unique from one another. Even a change as minute as incrementing the 6th decimal place of one parameter can have widely different changes to the resulting solution.</p>
                    </div>
                </div>
                <div className="fourth">
                    <div className="fourthText">
                        <h1>Transform</h1>
                        <p className="fourthPara">While the parameters are not mutable, owners have the ability to visually transform their artwork into a 3D animated representation from their original minted still image, and vice versa. As the cost of each transformation is set to a bonding curve, the mutability of the collection is limited by demand.</p>
                    </div>  
                </div>
                {/* spacer */}
                <div style={{height: '75px'}} />
            </div>
            <div className="ex-intro">
                <div className="collection-container">
                    <div className="lorenz">
                        <div className="lorenzText">
                            <div style={{height: '25px'}} />
                            <h1>The Lorenz Collection</h1>
                            <p className="lorenzPara">This collection consists of 6,720 unique sets of parameters to the Lorenz system of equations. The Lorenz system is a set of ordinary differential equations first studied by Edward Lorenz, illustrating “the butterfly effect.”</p>
                            <Link className="button" to={'./lorenz/'}>View More</Link>
                        </div>
                        <div className="collection-spacer" style={{width: '100px'}} />
                        <div>
                            <div style={{height: '50px'}} />
                            <img src={collage} alt="lorenzImg" className="lorenzImg" />
                            <div style={{height: '50px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default Home
