import React from 'react'
import { Link } from 'react-router-dom'
import '../css/footer.css'


function Footer() {
    return (
        <div className="footer">
            <div className="linkContainer">
                <div id="navButton">
                    <Link to={{ pathname: "https://twitter.com/ChaosTheoryNFTs" }} target="_blank">Twitter</Link>
                </div>
                <div id="navButton">
                    <Link to={{ pathname: "https://discord.gg/e6AHTN3dcH" }} target="_blank">Discord</Link>
                </div>
                <div id="navButton">
                    <Link to={{ pathname: "https://chaostheorynft.medium.com" }} target="_blank">Medium</Link>
                </div>
                <div id="navButton">
                    <Link>Terms</Link>
                </div>
            </div>
            <div className="con">
                <h5>Copyright © 2021 Chaos Theory</h5>
            </div>
        </div>
    )
}

export default Footer
