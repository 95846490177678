import React, { useEffect } from 'react'
import { scroller } from 'react-scroll'
import Navigation from '../components/Navigation'

import equations from '../assets/equations.png'
import '../css/Lorenz.css'

import image1 from '../assets/collection/1.png'
import image2 from '../assets/collection/9.png'
import image3 from '../assets/collection/3.png'
import image4 from '../assets/collection/4.png'
import image5 from '../assets/collection/10.png'
import image6 from '../assets/collection/6.png'
import image7 from '../assets/collection/7.png'
import image8 from '../assets/collection/8.png'
import image9 from '../assets/collection/2.png'

import butterfly from '../assets/content/butterfly.png'
import color from '../assets/content/color.png'
import dash from '../assets/content/dash.png'
import json from '../assets/content/json.png'


import collage from '../assets/collage.gif'
import animation from '../assets/animation1.gif'
import azimuth from '../assets/azimuth.gif'

function Lorenz(props) {

    const section = props.match.params.section;

    window.scrollTo(0, 0);

    const scrollToCollection = () => {
        scroller.scrollTo("contentMM", {
            duration: 1500,
            delay: 0,
            smooth: "easeInOutQuart",
          });
    }

    useEffect(() => {
        if (section === "mint")
        {
            scrollToCollection();
        }
    }, [section])

    return (
        <div>
            <div className="ex-intro">
                <Navigation page={'Lorenz'}/>
                <div id="header">The Lorenz Collection</div>
                <div className="grid">
                    <div className="cell">
                        <img src={image1} alt="introImg" className="cellImg" id="groupOne"/>
                    </div>
                    <div className="cell">
                        <img src={image2} alt="introImg" className="cellImg" id="groupOne"/>
                    </div>
                    <div className="cell">
                        <img src={image3} alt="introImg" className="cellImg" id="groupOne"/>
                    </div>
                    <div className="cell">
                        <img src={image4} alt="introImg" className="cellImg" id="groupTwo"/>
                    </div>
                    <div className="cell">
                        <img src={image5} alt="introImg" className="cellImg" id="groupTwo"/>
                    </div>
                    <div className="cell">
                        <img src={image6} alt="introImg" className="cellImg" id="groupTwo"/>
                    </div>
                    <div className="cell">
                        <img src={image7} alt="introImg" className="cellImg" id="groupThree"/>
                    </div>
                    <div className="cell">
                        <img src={image8} alt="introImg" className="cellImg" id="groupThree"/>
                    </div>
                    <div className="cell">
                        <img src={image9} alt="introImg" className="cellImg" id="groupThree"/>
                    </div>
                </div>
            </div>
            <div className="Lorenz">
                <div className="lorenz-content-container">
                    <div className="content">
                        <div className="contentText">
                            <h1 className="contentTitle">The Lorenz Attractor</h1>
                            <p className="contentPara">The Lorenz attractor is a complex system of equations detailing the flow of fluid under multiple varying conditions. The Lorenz Attractor serves as a fundamental example of chaotic behaviour, as having multiple independent variables makes it impossible to predict how the fluid flows beforehand. The resulting shape is similar to that of a butterfly.</p>
                        </div>
                        <div>
                            <img src={equations} alt="contentImg" className="contentImg" />
                        </div>
                    </div>
                    <div className="content">
                        <div>
                            <img src={json} alt="contentImg" className="contentImg" />
                        </div>
                        <div className="contentText">
                            <h1 className="contentTitle">Attributes</h1>
                            <p className="contentPara">Once generated randomly, the parameters describing the Lorenz are stored on Arweave—a permanent decentralized data storage protocol. As the parameters represent the fundamental concept of a Lorenz, they are used to derive the visual representations.</p>
                        </div>
                    </div>
                    <div className="content">
                        <div className="contentText">
                            <h1 className="contentTitle">Initial Condition</h1>
                            <p className="contentPara">The initial point for the Lorenz system of equations and the crux of this collection. As each generation guarantees a completely unique initial point, each resulting solution is unpredictably unique.</p>
                        </div>
                        <div>
                            <img src={butterfly} alt="contentImg" className="contentImg" />
                        </div>
                    </div>
                    <div className="content">
                        <div>
                            <img id="invert" src={color} alt="contentImg" className="contentImg"/>
                        </div>
                        <div className="contentText">
                            <h1 className="contentTitle">Palate</h1>
                            <p className="contentPara">Selects the sequential colormap that illustrates the Lorenz, with the starting points of Lorenz being more bright and the ending points being more saturated. The bank of sequential colormaps is depicted here.</p>
                        </div>
                    </div>
                    <div className="content">
                        <div className="contentText">
                            <h1 className="contentTitle">Azimuth, Altitude, and Radius</h1>
                            <p className="contentPara">Describes the position of the camera about a sphere encompassing the Lorenz. In static form, this is the frame of reference used for the 2D representation. In animated form, this position serves as the starting point from which the camera rotates about the sphere.</p>
                        </div>
                        <div>
                            <img src={azimuth} alt="contentImg" className="contentImg" id="revert"/>
                        </div>
                    </div>
                    <div className="content">
                        <div>
                            <img src={dash} alt="contentImg" className="contentImg" />
                        </div>
                        <div className="contentText">
                            <h1 className="contentTitle">Lines</h1>
                            <p className="contentPara">The continuity of the lines within the system of equations, ranging from solid to sparse, with a significant bias towards generating more continuous lines. Rarity: 60% Continuous, 25% Abundant, 10% Woven, and 5% Sparse.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ex-intro">
                <div className="Lorenz">
                    <div className="contentM" id="animate">
                        <div className="contentText">
                            <h1 className="contentTitle">Animate</h1>
                            <p className="contentPara">Soon after mint, owners will be able to access the transform functions of their NFTs on our dashboard. Owners will be able to convert a static representation of their Lorenz into an animated one or vice versa. As the cost of each transform scales with a bonding curve, the number of transformations will be capped by demand.</p>
                            <span className="button-disabled">Coming Soon</span>
                            {/* spacer */}
                            <div style={{height: '50px'}} />
                        </div>
                        <div className="content-image-containter">
                            <img src={animation} alt="contentImg" className="animateImg" />
                        </div>
                    </div>
                    <div className="contentMM" id="mint">
                        <div className="content-image-containter">
                            {/* spacer */}
                            <div style={{height: '50px'}} />
                            <img src={collage} alt="contentImg" className="lorenz-collection-image" />
                        </div>
                        <div className="contentText">
                            <h1 className="contentTitle">Mint</h1>
                            <p className="contentPara">Dropping late October, date to be determined. Once the drop has started, the button will become active and the Lorenzs will be mintable.</p>
                            <span className="button-disabled">Coming Soon</span>
                            {/* spacer */}
                            <div style={{height: '50px'}} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Lorenz
